import _JsonWebTokenError from "./JsonWebTokenError";
var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
var JsonWebTokenError = _JsonWebTokenError;
var TokenExpiredError = function (message, expiredAt) {
  JsonWebTokenError.call(this || _global, message);
  (this || _global).name = "TokenExpiredError";
  (this || _global).expiredAt = expiredAt;
};
TokenExpiredError.prototype = Object.create(JsonWebTokenError.prototype);
TokenExpiredError.prototype.constructor = TokenExpiredError;
exports = TokenExpiredError;
export default exports;