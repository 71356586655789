import _decode from "./decode";
import _verify from "./verify";
import _sign from "./sign";
import _JsonWebTokenError from "./lib/JsonWebTokenError";
import _NotBeforeError from "./lib/NotBeforeError";
import _TokenExpiredError from "./lib/TokenExpiredError";
var exports = {};
exports = {
  decode: _decode,
  verify: _verify,
  sign: _sign,
  JsonWebTokenError: _JsonWebTokenError,
  NotBeforeError: _NotBeforeError,
  TokenExpiredError: _TokenExpiredError
};
export default exports;
export const decode = exports.decode;