import _JsonWebTokenError from "./JsonWebTokenError";
var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
var JsonWebTokenError = _JsonWebTokenError;
var NotBeforeError = function (message, date) {
  JsonWebTokenError.call(this || _global, message);
  (this || _global).name = "NotBeforeError";
  (this || _global).date = date;
};
NotBeforeError.prototype = Object.create(JsonWebTokenError.prototype);
NotBeforeError.prototype.constructor = NotBeforeError;
exports = NotBeforeError;
export default exports;